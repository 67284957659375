import React, { useEffect, useState } from "react";
import ETable from "../components/editable_table/Etable";
import { Button, Input, Popconfirm, Skeleton, message } from "antd";
import { useSelector } from "react-redux";
import { useDeleteItemMutation, useFetchCategoryQuery, useFetchItemsQuery, useFetchItemQuery } from "../store/store";
import { useNavigate } from "react-router-dom";
import CreateItem from "./CreateItem";
import Itemform from "../components/quotationfrom/Itemform";
import Slidebar from "../components/sidebar/Slidebar";
import { BiEdit, BiTrash } from "react-icons/bi";

export default function Item() {
  const [show, setShow] = useState(false);
  const { item_page } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  const [user_id, setUser_id] = useState("");
  useEffect(() => {
    if (user?.is_superuser === true || user?.is_admin === true) {
    } else {
      setUser_id(user?.id);
    }
  }, [user]);
  const [search, setSearch] = useState("");

  const { data: itemsData, isLoading: itemsLoading, isFetching: itemsFetching, error: itemsError } = useFetchItemsQuery({
    val: item_page,
    search: search,
  });

  const { data: categoryData, isLoading: catLoading } = useFetchCategoryQuery();

  const { data: exportDataResult } = useFetchItemQuery({
    val: item_page,
    search: search,
  });


  const exportData = async () => {
    
    console.log("exportDataResult", exportDataResult)
    if (!exportDataResult || !categoryData) return;

    const categoryMap = categoryData.reduce((acc, curr) => {
      acc[curr.id] = curr.category;
      return acc;
    }, {});

    const csvData = exportDataResult.map((item, index) => {
      const itemCategory = categoryMap[item.item_category] || "";
      return {
        SrNo: index + 1,
        ItemName: item.item_name,
        RoomArea: itemCategory,
        Unit: item.unit,
        Costing: item.costing,
        Height: item.height,
        Width: item.width,
        Depth: item.depth,
        Length: item.length,
        SQFT: item.sqft,
        Numbers: item.numbers,
        RunningFoot: item.running_foot,
        Specifications: item.specifications,
        Quantity: item.quantity,
      };
    });

    const headers = [
      { label: "Sr No", key: "SrNo" },
      { label: "Item Name", key: "ItemName" },
      { label: "Room/Area", key: "RoomArea" },
      { label: "Unit", key: "Unit" },
      { label: "Costing", key: "Costing" },
      { label: "Height", key: "Height" },
      { label: "Width", key: "Width" },
      { label: "Depth", key: "Depth" },
      { label: "Length", key: "Length" },
      { label: "SQFT", key: "SQFT" },
      { label: "Numbers", key: "Numbers" },
      { label: "Running Foot", key: "RunningFoot" },
      { label: "Quantity", key: "Quantity" },
      { label: "Specifications", key: "Specifications" },
    ];

    const csvContent = [
      headers.map((header) => header.label).join(","),
      ...csvData.map((item) => headers.map((header) => item[header.key]).join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "items.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };


  const [deleteItem, deleteItemResponseInfo] = useDeleteItemMutation();

  useEffect(() => {
    if (deleteItemResponseInfo?.status === "fulfilled") {
      message.success("Delete Successful");
    }
  }, [deleteItemResponseInfo]);

  const navigate = useNavigate();
  const [formdata, setFormdata] = useState();
  const [id, setid] = useState();

  const create_item = () => {
    setFormdata();
    setid();
    setShow(true);
  };

  const editfun = (data) => {
    setFormdata(data);
    setid(data.id);
    setShow(true);
  };

  const deletethis = (data) => {
    if (data?.id) {
      deleteItem(data?.id);
    }
  };

  const [columns, setColumn] = useState();

  useEffect(() => {
    if (user_id) {
      setColumn([
        {
          title: "Sr.no",
          dataIndex: "id",
          key: "id",
          width: 100,
          render: (text, record, index) => {
            return <span>{item_page * 10 - 10 + index + 1}</span>;
          },
        },
        {
          title: "Item Name",
          dataIndex: "item_name",
          key: "id",
        },
        {
          title: "Room/Area",
          dataIndex: "item_category",
          key: "id",
          render: (text, record, index) => {
            return (
              <>
                {categoryData?.filter(
                  (item) => parseInt(item.id) === parseInt(record.item_category)
                )[0]?.category}
              </>
            );
          },
        },
        {
          title: "Unit",
          dataIndex: "unit",
          key: "id",
        },
        {
          title: "Costing",
          dataIndex: "costing",
          key: "id",
        },
        {
          title: ' ',
          key: 'id',
          fixed: 'right',
          width: 50,
          render: (record) => (
            <BiEdit
              className="bi-edit"
              style={{ width: "100%", height: "20px" }}
              onClick={(e) => {
                e.stopPropagation();
                editfun(record);
              }}
            />
          ),
        },
      ]);
    } else {
      setColumn([
        {
          title: "Sr.no",
          dataIndex: "id",
          key: "id",
          width: 100,
          render: (text, record, index) => {
            return <span>{item_page * 10 - 10 + index + 1}</span>;
          },
        },
        {
          title: "Item Name",
          dataIndex: "item_name",
          key: "id",
        },
        {
          title: "Room/Area",
          dataIndex: "item_category",
          key: "id",
          render: (text, record, index) => {
            return (
              <>
                {categoryData?.filter(
                  (item) => parseInt(item.id) === parseInt(record.item_category)
                )[0]?.category}
              </>
            );
          },
        },
        {
          title: "Unit",
          dataIndex: "unit",
          key: "id",
        },
        {
          title: "Costing",
          dataIndex: "costing",
          key: "id",
        },
        {
          title: ' ',
          key: 'id',
          fixed: 'right',
          width: 50,
          render: (record) => (
            <BiEdit
              className="bi-edit"
              style={{ width: "100%", height: "20px" }}
              onClick={(e) => {
                e.stopPropagation();
                editfun(record);
              }}
            />
          ),
        },
        {
          title: " ",
          key: "id",
          fixed: "right",
          width: 50,
          render: (record) => (
            <Popconfirm title="Sure to delete?" onConfirm={() => deletethis(record)}>
              <BiTrash
                className="bi-edit"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ width: "100%", height: "20px", color: "red" }}
              />
            </Popconfirm>
          ),
        },
      ]);
    }
  }, [user_id, item_page, categoryData]);

  const shows = (data) => {
    setShow(data);
  };

  const ItemForms = () => {
    return (
      <div className="model-con">
        <div className="model-box" style={{ width: "80vw" }}>
          <div>
            {id ? (
              <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                Update Item
              </h2>
            ) : (
              <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                Create Item
              </h2>
            )}
            {id ? (
              <Itemform
                datas={formdata}
                id={id}
                show={shows}
                category={categoryData}
                loading={catLoading}
              />
            ) : (
              <Itemform show={shows} category={categoryData} loading={catLoading} />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Slidebar />
      <div className="for-etable">
        <button
          style={{
            padding: "10px 40px",
            margin: "3rem 0",
            borderRadius: "7px",
            border: "none",
            backgroundColor: "#323a3d",
            color: "white",
            fontSize: "15px",
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          onClick={create_item}
        >
          Create New Item
        </button>
        <button
          style={{
            padding: "10px 40px",
            margin: "30px",
            borderRadius: "7px",
            border: "none",
            backgroundColor: "#323a3d",
            color: "white",
            fontSize: "15px",
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          onClick={exportData}
        >
          Export to CSV
        </button>
        <Input
          autoFocus
          type="text"
          placeholder="Enter Item Name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ marginBottom: "20px" }}
        />
        {itemsLoading ? (
          <Skeleton />
        ) : (
          <ETable
            data={itemsData}
            columns={columns}
            loading={itemsFetching}
            field={"item"}
            page={item_page}
            error={itemsError}
          />
        )}
        {show ? <ItemForms /> : null}
      </div>
    </div>
  );
}
