import { Button, Form, Input, message } from "antd";
import React, { useEffect } from "react";
import { useUpdateExecutionDesignMutation } from "../../../store/store";
import styled from "styled-components";

const onFinish = (data, updateEnquiry) => {
  const { id, img, title, model, ...formData } = data;

  const updatedData = {
    id,
    remark: formData.remark,
  };
  console.log("Updated Data: ", updatedData);
  console.log('Form data:', formData); 
  console.log('Original data:', data);
  updateEnquiry(updatedData);
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

const RemarkUpload = ({ item }) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [
    updateEnquiry,
    upadteEnquiryResponseInfo,
  ] = useUpdateExecutionDesignMutation();

  useEffect(() => {
    if (upadteEnquiryResponseInfo?.isSuccess) {
      message.success("Successfully Added..!!!");
    }
  }, [upadteEnquiryResponseInfo]);

  useEffect(() => {
    form.resetFields();
  }, [item]);

  return (
    <Style>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={(data) => {
          onFinish(data, updateEnquiry);
        }}
        style={{
          maxWidth: "100%",
        }}
        {...layout}
        initialValues={item}  // Setting initial values here
      >
        <Form.Item name="id" className="none"></Form.Item>
        <Form.Item name="model" className="none"></Form.Item>
        <Form.Item name="img" className="none"></Form.Item>
        <Form.Item name="title" className="none"></Form.Item>
        <Form.Item name="remark">
          <TextArea rows={2} placeholder="Enter Your Remark" className="remark-input" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ padding: "0 0.5em", background: "var(--pr-color)" }}
          >
            {item?.id ? <>Submit</> : null}
          </Button>
        </Form.Item>
      </Form>
    </Style>
  );
};

export default RemarkUpload;

const Style = styled.div`
  .ant-form-item {
    box-sizing: border-box;
    margin-bottom: 8px !important;
  }
  .remark-input {
    width: 200px;
  }
  @media screen and (max-width: 548px) {
    .remark-input {
      width: 200px;
    }
  }
`;
