import React from 'react'
import CreateExecution from './CreateExecution'
import { useFetchExecutionModelQuery } from '../../store/store';
import Slidebar from '../../components/sidebar/Slidebar';
import { ClipLoader } from 'react-spinners';
import { useParams } from 'react-router-dom';
import NoContentTitle from '../../components/atoms/NoContentTitle';

const MainExecution = () => {
  const {id}=useParams()
    const {
        data: data,
        isLoading: loading,
        isFetching: fetch,
        error: error,
      } = useFetchExecutionModelQuery(id);
      console.log(data)
   
  return (
    <div>
        <Slidebar/>
        {!data?.length>0?
      <NoContentTitle title="Execution Work" color="#6bc9d6;" />:

      <CreateExecution data={data}/>
        }
    </div>
  )
}

export default MainExecution
