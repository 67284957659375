import React from 'react'
import MainEnquiryForm from './MainEnquiryForm'

const SuperEnquiryForm = () => {
  return (
    <div>
      <MainEnquiryForm page={"client"}/>
    </div>
  )
}

export default SuperEnquiryForm
