const images = [
  {
    id: "1",
    imageName: "image1.jpeg",
    tag: "home interior",
  },
  {
    id: "2",
    imageName: "image2.jpeg",
    tag: "home interior",
  },
  {
    id: "3",
    imageName: "image3.jpeg",
    tag: "commercial",
  },
  {
    id: "4",
    imageName: "image4.jpeg",
    tag: "home interior",
  },
  {
    id: "5",
    imageName: "image5.jpeg",
    tag: "home interior",
  },
  {
    id: "6",
    imageName: "image6.jpeg",
    tag: "home interior",
  },
  {
    id: "7",
    imageName: "image7.jpeg",
    tag: "home interior",
  },
  {
    id: "8",
    imageName: "image8.jpeg",
    tag: "home interior",
  },
  {
    id: "9",
    imageName: "image9.jpeg",
    tag: "home interior",
  },
  {
    id: "10",
    imageName: "image10.jpeg",
    tag: "commercial",
  },
  {
    id: "11",
    imageName: "image11.jpeg",
    tag: "home interior",
  },
  {
    id: "12",
    imageName: "image12.jpeg",
    tag: "commercial",
  },
  {
    id: "13",
    imageName: "image14.jpeg",
    tag: "commercial",
  },
  {
    id: "14",
    imageName: "image15.jpeg",
    tag: "commercial",
  },
  {
    id: "15",
    imageName: "image16.jpeg",
    tag: "commercial",
  },
  {
    id: "16",
    imageName: "image17.jpeg",
    tag: "home interior",
  },
  {
    id: "17",
    imageName: "image18.jpg",
    tag: "retail shop",
  },
  {
    id: "18",
    imageName: "image19.png",
    tag: "retail shop",
  },
  {
    id: "19",
    imageName: "image20.png",
    tag: "retail shop",
  },
  {
    id: "20",
    imageName: "image21.png",
    tag: "retail shop",
  },
  {
    id: "21",
    imageName: "OrigiNatureImg-01.jpg",
    tag: "retail shop",
  },
  {
    id: "22",
    imageName: "OrigiNatureImg-02.jpg",
    tag: "retail shop",
  },
  {
    id: "23",
    imageName: "OrigiNatureImg-03.jpg",
    tag: "retail shop",
  },
  {
    id: "24",
    imageName: "OrigiNatureImg-04.jpg",
    tag: "retail shop",
  },
  {
    id: "25",
    imageName: "OrigiNatureImg-05.jpg",
    tag: "retail shop",
  },
  {
    id: "26",
    imageName: "OrigiNatureImg-06.jpg",
    tag: "retail shop",
  },
  {
    id: "27",
    imageName: "retail01.png",
    tag: "retail shop",
  },
  {
    id: "28",
    imageName: "retail-02.png",
    tag: "retail shop",
  },
  {
    id: "29",
    imageName: "retail-03.png",
    tag: "retail shop",
  },
  {
    id: "30",
    imageName: "retail-04.png",
    tag: "retail shop",
  },
  {
    id: "31",
    imageName: "newimg-01.png",
    tag: "home interior",
  },
  {
    id: "32",
    imageName: "newimg-02.png",
    tag: "home interior",
  },
  {
    id: "33",
    imageName: "newimg-03.png",
    tag: "home interior",
  },
  {
    id: "34",
    imageName: "newimg-04.png",
    tag: "home interior",
  },
  {
    id: "35",
    imageName: "newimg-05.png",
    tag: "home interior",
  },
  {
    id: "36",
    imageName: "retail_new-imgs-01.jpg",
    tag: "retail shop",
  },
  {
    id: "37",
    imageName: "retail_new-imgs-02.jpg",
    tag: "retail shop",
  },
  {
    id: "38",
    imageName: "retail_new-imgs-03.jpg",
    tag: "retail shop",
  },
  {
    id: "39",
    imageName: "retail_new-imgs-04.jpg",
    tag: "retail shop",
  },
  {
    id: "40",
    imageName: "retail_new-imgs-05.jpg",
    tag: "retail shop",
  },
  {
    id: "41",
    imageName: "retail-shop-1.png",
    tag: "retail shop",
  },
  {
    id: "42",
    imageName: "retail-shop-2.png",
    tag: "retail shop",
  },
  {
    id: "43",
    imageName: "retail-shop-3.png",
    tag: "retail shop",
  },
  {
    id: "44",
    imageName: "retail-shop-4.png",
    tag: "retail shop",
  },
  {
    id: "45",
    imageName: "home-int-1.png",
    tag: "home interior",
  },
  {
    id: "46",
    imageName: "home-int-2.png",
    tag: "home interior",
  },
  {
    id: "47",
    imageName: "home-int-3.png",
    tag: "home interior",
  },
  {
    id: "48",
    imageName: "home-int-4.png",
    tag: "home interior",
  },
  {
    id: "49",
    imageName: "home-int-5.png",
    tag: "home interior",
  },
  {
    id: "50",
    imageName: "home-int-6.png",
    tag: "home interior",
  },
  {
    id: "51",
    imageName: "homeintapr23.jpeg",
    tag: "home interior",
  },
  {
    id: "52",
    imageName: "homeintapr23_1.jpeg",
    tag: "home interior",
  },
  {
    id: "53",
    imageName: "homeintapr23_2.jpeg",
    tag: "home interior",
  },
  {
    id: "54",
    imageName: "homeintapr23_3.jpeg",
    tag: "home interior",
  },
  {
    id: "55",
    imageName: "3dimage1.png",
    tag: "home interior",
  },
  {
    id: "56",
    imageName: "3dimage2.png",
    tag: "home interior",
  },
  {
    id: "57",
    imageName: "3dimage3.png",
    tag: "commercial",
  },
  {
    id: "58",
    imageName: "3dimage4.png",
    tag: "home interior",
  },
  {
    id: "59",
    imageName: "3dimage5.png",
    tag: "home interior",
  },
  {
    id: "60",
    imageName: "3dimage6.png",
    tag: "home interior",
  },
  {
    id: "61",
    imageName: "3dimage7.png",
    tag: "home interior",
  },
  {
    id: "62",
    imageName: "3dimage8.png",
    tag: "home interior",
  },
  {
    id: "63",
    imageName: "3dimage9.png",
    tag: "home interior",
  },
  {
    id: "64",
    imageName: "retqilnew1.jpeg",
    tag: "retail shop",
  },
  {
    id: "65",
    imageName: "retqilnew2.jpeg",
    tag: "retail shop",
  },
  {
    id: "66",
    imageName: "retqilnew3.jpeg",
    tag: "retail shop",
  },
  {
    id: "67",
    imageName: "retqilnew4.jpeg",
    tag: "retail shop",
  },
  {
    id: "68",
    imageName: "15.jpg",
    tag: "home interior",
  },

  {
    id: "68",
    imageName: "15.jpg",
    tag: "home interior",
  },
  {
    id: "69",
    imageName: "16.jpg",
    tag: "home interior",
  },
  {
    id: "70",
    imageName: "17.jpg",
    tag: "home interior",
  },
  {
    id: "71",
    imageName: "18.jpg",
    tag: "home interior",
  },
  {
    id: "72",
    imageName: "19.jpg",
    tag: "home interior",
  },
  {
    id: "73",
    imageName: "20.jpg",
    tag: "home interior",
  },
  {
    id: "74",
    imageName: "21.jpg",
    tag: "home interior",
  },
  {
    id: "75",
    imageName: "22.jpg",
    tag: "home interior",
  },
  {
    id: "76",
    imageName: "23.jpg",
    tag: "home interior",
  },
  {
    id: "77",
    imageName: "24.jpg",
    tag: "home interior",
  },
  {
    id: "78",
    imageName: "25.jpg",
    tag: "home interior",
  },
  {
    id: "79",
    imageName: "26.jpg",
    tag: "home interior",
  },
  {
    id: "80",
    imageName: "27.jpg",
    tag: "home interior",
  },
  {
    id: "81",
    imageName: "28.jpg",
    tag: "home interior",
  },
  {
    id: "82",
    imageName: "29.jpg",
    tag: "home interior",
  },
  {
    id: "83",
    imageName: "30.jpg",
    tag: "home interior",
  },
  {
    id: "84",
    imageName: "31.jpg",
    tag: "home interior",
  },
  {
    id: "85",
    imageName: "32.jpg",
    tag: "home interior",
  },
  {
    id: "86",
    imageName: "33.jpg",
    tag: "home interior",
  },
  {
    id: "87",
    imageName: "34.jpg",
    tag: "home interior",
  },
  {
    id: "88",
    imageName: "35.jpg",
    tag: "home interior",
  },
  {
    id: "89",
    imageName: "36.jpg",
    tag: "home interior",
  },
  {
    id: "90",
    imageName: "37.jpg",
    tag: "home interior",
  },
  {
    id: "91",
    imageName: "38.jpg",
    tag: "home interior",
  },
  {
    id: "92",
    imageName: "39.jpg",
    tag: "home interior",
  },
  {
    id: "93",
    imageName: "40.jpg",
    tag: "home interior",
  },
  {
    id: "94",
    imageName: "41.jpg",
    tag: "home interior",
  },
  {
    id: "95",
    imageName: "42.jpg",
    tag: "home interior",
  },
  {
    id: "96",
    imageName: "43.jpg",
    tag: "home interior",
  },
  {
    id: "97",
    imageName: "44.jpg",
    tag: "home interior",
  },
  {
    id: "98",
    imageName: "45.jpg",
    tag: "home interior",
  },
  {
    id: "99",
    imageName: "46.jpg",
    tag: "home interior",
  },
  {
    id: "100",
    imageName: "47.jpg",
    tag: "home interior",
  },
  {
    id: "101",
    imageName: "48.jpg",
    tag: "home interior",
  },
  {
    id: "102",
    imageName: "49.jpg",
    tag: "home interior",
  },
  {
    id: "103",
    imageName: "50.jpg",
    tag: "home interior",
  },
  {
    id: "104",
    imageName: "51.jpg",
    tag: "home interior",
  },
  {
    id: "105",
    imageName: "52.jpg",
    tag: "home interior",
  },
  {
    id: "106",
    imageName: "53.jpg",
    tag: "home interior",
  },
  {
    id: "107",
    imageName: "54.jpg",
    tag: "home interior",
  },
  {
    id: "108",
    imageName: "55.jpg",
    tag: "home interior",
  },
  {
    id: "109",
    imageName: "56.jpg",
    tag: "home interior",
  },
  {
    id: "110",
    imageName: "57.jpg",
    tag: "home interior",
  },
  {
    id: "111",
    imageName: "58.jpg",
    tag: "home interior",
  },
  {
    id: "112",
    imageName: "59.jpg",
    tag: "home interior",
  },
  {
    id: "113",
    imageName: "60.jpg",
    tag: "home interior",
  },
  {
    id: "114",
    imageName: "61.jpg",
    tag: "home interior",
  },
  {
    id: "115",
    imageName: "62.jpg",
    tag: "home interior",
  },
  {
    id: "116",
    imageName: "63.jpg",
    tag: "home interior",
  },
  {
    id: "117",
    imageName: "64.jpg",
    tag: "home interior",
  },
  {
    id: "118",
    imageName: "65.jpg",
    tag: "home interior",
  },
  {
    id: "119",
    imageName: "66.jpg",
    tag: "home interior",
  },
  {
    id: "120",
    imageName: "67.jpg",
    tag: "home interior",
  },
];

export default images;
