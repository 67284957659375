// axiosInstance.js
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';


const axiosInstance = axios.create({
    baseURL: 'https://www.spacemate.in/', // Replace with your API base URL
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('userToken');

        if (token) {
            const decoded = jwtDecode(token);
            console.log('decoded: ', decoded);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                // Token is expired, handle the case (e.g., refresh token or redirect to login)
                alert('Session expired, please log in again.');
                localStorage.removeItem('userToken');
                localStorage.removeItem('user');
                window.location.href = '/login';
                return Promise.reject('Token expired');
            }
            // Attach the token to the request
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
