const WorkApiDisplay = [
    {
      id: 1,
      logo: "fas fa-pencil-ruler",
      title: "Design Support",
      info: "Our inhouse design team gets engaged with customer for better utilization of the space. We give complete visualization support for easy decision making.",
    },
    {
      id: 2,
      logo: "fas fa-wallet",
      title: "Perfect Product Match",
      info: "Based on the need of customers our experts recommend perfect solutions. wherever needed we suggest customized solutions to customers FOR a better look and feel.",
    },
    {
      id: 3,
      logo: "fas fa-user-tie",
      title: "Experience",
      info: "Spacemate is comprised of highly skilled individuals and experts with Industry experience. Their know how of working in a wide range of industries is what makes them best for your developing your space.",
    },
  ];
  
  export default WorkApiDisplay;