import React from 'react'
import MainEnquiryForm from './MainEnquiryForm'

const MoodBoardEdit = () => {
  return (
    <div>
      <MainEnquiryForm page={"mood"}/>
      
    </div>
  )
}

export default MoodBoardEdit
