import styled from "styled-components";

export const ServicepgStyle = styled.div`
.services-main-container {
    margin-top: 4rem;
    padding: 5rem 4rem;
}

.input-group-button__serv {
    align-content: center;
    text-align: center;
    justify-content: center;
    padding: 1rem 2rem;
    border-radius: 1rem;
    background-color: var(--primary-color);
    color: #000;
    font-size: 1.6rem;
    transition: ease-in;
    width: 15rem;
}
.input-group-button__serv:hover, 
.input-group-button__serv:focus {
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.25em);
}

.work-container-subdiv-service {
    padding: 0 5rem;
}

.main-hero-para-service {
    margin-top: 3rem;
    padding: 0 2rem;
}

.work-container-subdiv-service-block {
    padding: 0 2rem;
}

.gallery-link-button {
    display:inline-block;
    background-color: var(--primary-color);
    padding: 1.5rem;
    margin-top: 2rem;
    border-radius: 1rem;
    font-size: 1.6rem;
}
.gallery-link-button:hover {
    background-color: transparent;
    padding: 1.3rem;
    margin-top: 2rem;
    border-radius: 1rem;
    font-size: 1.6rem;
    border: 2px solid var(--primary-color);
}

.flex-service{

}

.flex-service1{

}

.service-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  padding-left: 2rem;
}

.service-item {
  display: flex;
  align-items: center;
}
.flex-service1-items{
  margin: 2rem 0;
  padding: 0.2rem 0;
  h4{
    margin-bottom: 15px !important;
  }
}

.icon-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.service-icon {
  width: 70%;
  height: 70%;
  object-fit: contain;
}


@media screen and (max-width: 550px) {
    .services-main-container {
        margin-top: 5rem;
        padding-top: 1rem;
        
    }

    .main-hero-para-service {
    font-weight: lighter;
    /* text-align: justify; */
    margin-top: 2rem;
    }
    /* color: var(--main-text-color); */

    .work-container-subdiv-service {
        padding: 0
    }
    
    .main-hero-para-service {
        margin-top: 3rem;
        padding: 0 0;
    }

    .work-container-subdiv-service-block {
        padding: 0 0rem;
    }

    .service-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  padding-left: 2rem;
}

.service-item {
  display: flex;
  align-items: center;
}


.icon-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.service-icon {
  width: 70%;
  height: 70%;
  object-fit: contain;
}
    

}

@media (max-width: 750px) {
    .work-container-subdiv-service {
        padding: 0
    }
}

`;