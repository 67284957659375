import React from 'react';
import './ResCarousal.css';


const ResCarousal = () => {
    return (
        <div className='caraousal-res'>
            <div className="imgSlider">
            </div>
            
        </div>
    )
}

export default ResCarousal
