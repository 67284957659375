import React from 'react';
import { Link } from 'react-router-dom';
import ResCarousal from '../../components/gallery/ResCarousal';
import ComCarousal from '../../components/gallery/ComCarousal';
import { ServicepgStyle } from './Servicepg.style';
import "./Servicepg.css";
// Import your PNG images
import drawingIcon from '../../assets/ServicesIcons/2D-drawingIcon.png';
import designIcon from '../../assets/ServicesIcons/3D-drawingIcon.png';
import materialIcon from '../../assets/ServicesIcons/BillOfMaterial.png';
import quantityIcon from '../../assets/ServicesIcons/BillOfQuantity.png';
import detailingIcon from '../../assets/ServicesIcons/DesignDetailing.png';
import costIcon from '../../assets/ServicesIcons/CostEstimation.png';

const Servicepg = () => {
  return (
    <>
      <ServicepgStyle>
        <div className="mt-5">
          <section className="services-main-container px-10">
            <div className="container service-container">
              <div
                className="section-head col-sm-12 px-5"
                style={{ display: "flex" }}
              >
                <div className="flex-service">
                  <h4>
                    <span>What we do -</span> What do we Provide?
                  </h4>
                  <div className="service-list">
                    <div className="service-item">
                      <div className="icon-circle">
                        <img
                          src={drawingIcon}
                          alt="2D Drawings"
                          className="service-icon"
                        />
                      </div>
                      <p>2D Drawings</p>
                    </div>
                    <div className="service-item">
                      <div className="icon-circle">
                        <img
                          src={designIcon}
                          alt="3D Designs"
                          className="service-icon"
                        />
                      </div>
                      <p>3D Designs</p>
                    </div>
                    <div className="service-item">
                      <div className="icon-circle">
                        <img
                          src={materialIcon}
                          alt="Bill Material"
                          className="service-icon"
                        />
                      </div>
                      <p>Bill Material</p>
                    </div>
                    <div className="service-item">
                      <div className="icon-circle">
                        <img
                          src={quantityIcon}
                          alt="Bill of Quantity"
                          className="service-icon"
                        />
                      </div>
                      <p>Bill of Quantity</p>
                    </div>
                    <div className="service-item">
                      <div className="icon-circle">
                        <img
                          src={detailingIcon}
                          alt="Design Detailing"
                          className="service-icon"
                        />
                      </div>
                      <p>Design Detailing</p>
                    </div>
                    <div className="service-item">
                      <div className="icon-circle">
                        <img
                          src={costIcon}
                          alt="Cost Estimation"
                          className="service-icon"
                        />
                      </div>
                      <p>Cost Estimation</p>
                    </div>
                  </div>
                </div>
                <div className="flex-service1">
                  <div className="flex-service1-items">
                    <h4> 2D Drawings</h4>
                    <p>
                      Furniture Plan. Ceiling Plan for Gypsum / POP Ceiling Plan
                      Electrical. Wall Electrical Plan. Furniture Elevation.
                      Flooring Plan.
                    </p>
                  </div>
                  <div className="flex-service1-items">
                    <h4> 3D Design</h4>
                    <p>3D Mood board references. Modeling. 3D views render.</p>
                  </div>
                  <div className="flex-service1-items">
                    <h4>What is Bill of Material?</h4>
                    <p>
                      It includes all required details of material i.e Type of
                      material,Specifications of material, Brand of material.
                    </p>
                  </div>
                  <div className="flex-service1-items">
                    <h4>What is Bill of Quantity?</h4>
                    <p>Quantity details of material, Sizes of material.</p>
                  </div>
                </div>
              </div>
              <div className="section-head col-sm-12 px-5">
                <h4>
                  <span>Design</span> Services
                </h4>
                <p>
                  Educating clients about design provided by us. Also if
                  required to the respective vendors, we make them understand
                  designs, material and its application.
                </p>
                <p>
                  <b>Tentative Design Duration -</b> For 2D and 3D required 15
                  to 20 days, depending on the scope of work and inputs and
                  approval provided by clients.
                </p>
                <p>
                  <b>What Details required from Client -</b>
                  Civil Plan. Sizes of room. Photo of room. Video of property.
                  Payment Terms - Work advances 50% of the Total Package.
                  Remaining 50% to start 3D Design.
                </p>
              </div>
              <div className="section-head col-sm-12 px-5">
                <h4>
                  <span>Design</span> & Consultancy
                </h4>
                <p>
                  <b>Design Detailing -</b>
                  Educating clients about design provided by us. Also if
                  required to the respective vendors, we make them understand
                  designs, material and its application.
                </p>
                <p>
                  <b>Design Duration -</b> For 2D and 3D required 15 to 20 days,
                  depending on the scope of work. Educating vendors how to
                  execute given designs, providing a plan of execution step by
                  step. Details required from the customer, sizes of room /
                  civil plan / Photo and Video of property.
                </p>
              </div>
              <div className="section-head col-sm-12 px-5">
                <h4>
                  <span>Turnkey Project with</span> Material and Execution
                </h4>
                <p>
                  Turnkey projects involve project designing, planning, material
                  supply, vendor Management etc.
                </p>
                <p>
                  During the turnkey project we do complete 2D drawing and 3D
                  design. Key responsibilities in turnkey projects are
                  designing, material selections, supply of material and vendor
                  management.
                </p>
                <p>
                  <b>In material Selection -</b> We assist clients to select the
                  finishing and furnishing material as per the scope of work. We
                  recommend the material as per design and project estimate.
                </p>
                <p>
                  <b>Project Execution -</b> We take care of supply of required
                  material and workforce. Our supervisor visits the site for
                  inspection of work to ensure work is being done as required.
                </p>
              </div>
              <div className="row work-container-subdiv-service">
                <div className="col-12 col-lg-6 col-sm-6 work-container-subdiv-service-block">
                  <div className="item">
                    <span className="icon feature_box_col_two">
                      <i className="fas fa-landmark"></i>
                    </span>
                    <h2>Commercial Turnkey Projects</h2>
                    <p className="main-hero-para-service w-100">
                      <ComCarousal />
                      <Link to="/gallery" className="gallery-link-button">
                        {" "}
                        More Images{" "}
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-sm-6 work-container-subdiv-service-block">
                  <div className="item">
                    <span className="icon feature_box_col_two">
                      <i className="fas fa-building"></i>
                    </span>
                    <h2>Residential Turnkey Projects</h2>
                    <p className="main-hero-para-service w-100">
                      <ResCarousal />
                      <Link to="/gallery" className="gallery-link-button">
                        {" "}
                        More Images{" "}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </ServicepgStyle>
    </>
  );
}

export default Servicepg;
