import React from 'react'
import './ThankYoucont.css'

const ThankYoucont = () => {
    return (
        <>
        <div className="container">
            <div className="d-flex justify-content-center">
                <div className="thankyou-pg-cont">
                    <h1>Thank You</h1>
                    <p></p>
                </div>   
            </div>   
        </div>
        </>
    )
}

export default ThankYoucont
