import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import { useSelector } from "react-redux";
import * as SiIcons from "react-icons/si";
import * as SlIcon from "react-icons/sl";
import * as GiIcons from "react-icons/gi";
import * as BiIcons from "react-icons/bi";
import * as GoIcons from "react-icons/go";
export const SlidebarDataSuper = [
  {
    title: "Sales",
    icon: <SiIcons.SiSalesforce />,
    iconclosed: <RiIcons.RiArrowDownFill />,
    iconopened: <RiIcons.RiArrowUpFill />,
    subnav: [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: <RiIcons.RiDashboard2Fill />,
      },
      {
        title: "Enquiry",
        path: "/enquiry-table",
        icon: <AiIcons.AiOutlineForm />,
      },
      {
        title: "Prospect",
        path: "/prospect",
        icon: <FaIcons.FaWpforms />,
      },
      {
        title: "Quotation",
        path: "/quotation",
        icon: <MdIcons.MdAttachMoney />,
      },
      {
        title: "Client",
        path: "/client",
        icon: <FaIcons.FaPeopleCarry />,
      },

      
    ],
  },
  {
    title: "Design",
    path: "/design-client",
    icon: <MdIcons.MdDesignServices />,
  },
  {
    title: "Execution",
    path: "/execution-table",
    icon: <GiIcons.GiExecutionerHood />,
  },
  {
    title: "Accounts",
    icon: <MdIcons.MdAccountCircle />,
    iconclosed: <RiIcons.RiArrowDownFill />,
    iconopened: <RiIcons.RiArrowUpFill />,
    subnav: [
      {
        title: "Vendor",
        path: "/vendor",
        icon: <BiIcons.BiBody />,
      },
      {
        title: "Order",
        path: "/order-table",
        icon: <FaIcons.FaClipboardList />,
      },
    ],
  },
  {
    title: "HR",
    icon: <GiIcons.GiHumanPyramid />,
    iconclosed: <RiIcons.RiArrowDownFill />,
    iconopened: <RiIcons.RiArrowUpFill />,
    subnav: [
      {
        title: "Employee",
        path: "/employee",
        icon: <SlIcon.SlPeople />,
      },
    ],
  },
  {
    title: "Setting",
    icon: <MdIcons.MdSettings />,
    iconclosed: <RiIcons.RiArrowDownFill />,
    iconopened: <RiIcons.RiArrowUpFill />,
    subnav: [
      {
        title: "Item",
        path: "/item",
        icon: <GoIcons.GoChecklist  />,
      },
    ],
  },

];
export const SlidebarDataAdmin = [
  {
    title: "Sales",
    icon: <SiIcons.SiSalesforce />,
    iconclosed: <RiIcons.RiArrowDownFill />,
    iconopened: <RiIcons.RiArrowUpFill />,
    subnav: [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: <RiIcons.RiDashboard2Fill />,
      },
      {
        title: "Enquiry",
        path: "/enquiry-table",
        icon: <AiIcons.AiOutlineForm />,
      },
      {
        title: "Prospect",
        path: "/prospect",
        icon: <FaIcons.FaWpforms />,
      },
      {
        title: "Quotation",
        path: "/quotation",
        icon: <MdIcons.MdAttachMoney />,
      },
      {
        title: "Client",
        path: "/client",
        icon: <FaIcons.FaPeopleCarry />,
      },
    ],
  },
  {
    title: "Design",
    path: "/design-client",
    icon: <MdIcons.MdDesignServices />,
  },
  {
    title: "Execution",
    path: "/execution-table",
    icon: <GiIcons.GiExecutionerHood />,
  },
  {
    title: "Accounts",
    icon: <MdIcons.MdAccountCircle />,
    iconclosed: <RiIcons.RiArrowDownFill />,
    iconopened: <RiIcons.RiArrowUpFill />,
    subnav: [
      {
        title: "Vendor",
        path: "/vendor",
        icon: <BiIcons.BiBody />,
      },
      {
        title: "Order",
        path: "/order-table",
        icon: <FaIcons.FaClipboardList />,
      },
    
    ],
  },
  {
    title: "HR",
    icon: <GiIcons.GiHumanPyramid />,
    iconclosed: <RiIcons.RiArrowDownFill />,
    iconopened: <RiIcons.RiArrowUpFill />,
    subnav: [
      {
        title: "Employee",
        path: "/employee",
        icon: <SlIcon.SlPeople />,
      },
    ],
  },
  {
    title: "Setting",
    icon: <MdIcons.MdSettings />,
    iconclosed: <RiIcons.RiArrowDownFill />,
    iconopened: <RiIcons.RiArrowUpFill />,
    subnav: [
      {
        title: "Item",
        path: "/item",
        icon: <GoIcons.GoChecklist  />,
      },
    ],
  },
  






];
export const SalesAndMarketing = [

  {
    title: "Sales",
    icon: <SiIcons.SiSalesforce />,
    iconclosed: <RiIcons.RiArrowDownFill />,
    iconopened: <RiIcons.RiArrowUpFill />,
    subnav: [
      // {
      //   title: "Dashboard",
      //   path: "/dashboard",
      //   icon: <FaIcons.FaClipboardList />,
      // },
      {
        title: "Enquiry",
        path: "/enquiry-table",
        icon: <AiIcons.AiOutlineForm />,
      },
      {
        title: "Prospect",
        path: "/prospect",
        icon: <FaIcons.FaWpforms />,
      },
      {
        title: "Quotation",
        path: "/quotation",
        icon: <MdIcons.MdAttachMoney />,
      },
      {
        title: "Client",
        path: "/client",
        icon: <FaIcons.FaPeopleCarry />,
      },

      
    ],
  },
  {
    title: "Setting",
    icon: <MdIcons.MdSettings />,
    iconclosed: <RiIcons.RiArrowDownFill />,
    iconopened: <RiIcons.RiArrowUpFill />,
    subnav: [
      {
        title: "Item",
        path: "/item",
        icon: <GoIcons.GoChecklist  />,
      },
    ],
  },

];

export const SlidebarDataExecutionarHead = [

  {
    title: "Project",
    path: "/execution-table",
    icon: <GiIcons.GiExecutionerHood />,
  },
];

export const SliderForDesignHead = [

  {
    title: "Design",
    path: "/design-client",
    icon: <MdIcons.MdDesignServices />,
  },


];
export const SlidebarDataExecutionarHead2 = [

  {
    title: "Enquiry",
    path: "/enquiry-table",
    icon: <AiIcons.AiOutlineForm />,
  },

  {
    title: "Project",
    path: "/execution-table",
    icon: <GiIcons.GiExecutionerHood />,
  },
];
