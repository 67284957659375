
import React from 'react'
import PdfViewer from '../../commonpage/PdfViewer';
import { useSelector } from 'react-redux';
import { url } from '../../../store/mutation/url';
import Slidebar from '../../../components/sidebar/Slidebar';
import NoContentTitle from '../../../components/atoms/NoContentTitle';
const FurniturePlan = () => {
    const { user, userToken, loading, checkAuthLoading ,isAuthenticated} = useSelector(
        (state) => state.user
      );
      const {enquiry} = user 
    const {proposed_furniture_plan} = enquiry || {}
  return (
    <>
      <Slidebar />
      <div className="body-width-vw" style={{ margin: "auto" }}>
        <h2 className="e-table-h2">Furniture Plan</h2>
        {proposed_furniture_plan ? (
          <PdfViewer data={`${url}${proposed_furniture_plan}`} />
        ) : (
          <NoContentTitle title="Furniture plan is not uploaded" />
        )}
      </div>
    </>
  );
}


export default FurniturePlan
