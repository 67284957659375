import {
  Button,
  Col,
  Form,
  Row,
  Space,
  message,
} from "antd";
import React, { useEffect } from "react";
import "./vendorForm.css";
import { useCreateEmployeeMutation, useUpadteEmployeeMutation } from "../../store/store";
import InputField from "../atoms/InputField";
import { EmpVendorfields as commonFields } from "./fields";
import FormButtons from "../atoms/FormButtons.js";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const onUpdate = (data, updateClient, id, show) => {
  data.id = id;
  if (JSON.parse(localStorage.getItem("user"))?.id) {
    data.user_client_id = JSON.parse(localStorage.getItem("user"))?.id;
    data.user_client_name = JSON.parse(localStorage.getItem("user"))?.username;
    updateClient(data);
  } else {
    message.error("You are unauthorized");
  }
};

const onFinish = (data, createClient, show) => {
  if (JSON.parse(localStorage.getItem("user"))?.id) {
    data.user_client_id = JSON.parse(localStorage.getItem("user"))?.id;
    data.user_client_name = JSON.parse(localStorage.getItem("user"))?.username;
    createClient(data);
  } else {
    message.error("You are unauthorized");
  }
};

const Employeeform = ({ datas, id, show }) => {
  const [createClient, createClientResponseInfo] = useCreateEmployeeMutation();
  const [updateClient, updateClientResponseInfo] = useUpadteEmployeeMutation();
  const fields = commonFields.map((field) => 
    field.name === "name"
      ? { ...field, label: "Employee Name", placeholder: "Enter Your Employee Name" }
      : field
  );
  useEffect(() => {
    if (createClientResponseInfo?.status === "fulfilled") {
      message.success("Employee Created");
      show(false);
    }
    if (updateClientResponseInfo?.status === "fulfilled") {
      message.success("Employee Updated");
      show(false);
    }
  }, [createClientResponseInfo, updateClientResponseInfo]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="form-box">
        <Form
          name="dynamic_form_nest_item"
          onFinish={(data) => {
            if (datas) {
              onUpdate(data, updateClient, id, show);
            } else {
              onFinish(data, createClient, show);
            }
          }}
          style={{
            maxWidth: "100%",
            height: "68vh",
          }}
          {...layout}
          initialValues={datas}
        >
          <Row gutter={24}>
            {fields.map((field, index) => (
              <Col span={field.colSpan} key={index}>
                <InputField
                  name={field.name}
                  label={field.label}
                  placeholder={field.placeholder}
                  required={field.required}
                  type={field.type}
                  rules={field.rules}
                />
              </Col>
            ))}
          </Row>
          <div style={{ textAlign: "right" }}>
            <Space size="small">
              <FormButtons datas={datas} show={show} />
            </Space>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Employeeform;
