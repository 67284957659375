const serviceapi = [
    {
        id: 1,
        logo: "fas fa-landmark",
        title: "Commercial Turnkey projects",
        info: "We at Spacemate have a great experience in handling big Commercial Turnkey projects.",
    },
    {
        id: 1,
        logo: "fas fa-building",
        title: "Residential Turnkey projects",
        info: "We at do all kinds of Residential turnkey projects at great scale.",
    },

]

export default serviceapi;