import React from 'react';
import './ComCarousal.css';


const ComCarousal = () => {
    return (
        <div className='caraousal-com'>
            <div className="imgSlider-com">
            </div>
            
        </div>
    )
}

export default ComCarousal
