import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'antd';

const RemarkInput = ({ text, record, updateEnquiry }) => {
  const [remark, setRemark] = useState(""); 
  const textareaRef = useRef(null);

 
  useEffect(() => {
    setRemark(text);
  }, [text]);

 
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; 
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; 
    }
  }, [remark]);

  const handleInputChange = (e) => {
    setRemark(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); 
      Modal.confirm({
        title: 'Confirm Update',
        content: 'Are you sure you want to update the remark?',
        onOk() {
          const {
            moon_board,
            proposed_furniture_plan,
            floor_plain,
            ...data
          } = record;
          updateEnquiry({ ...data, remark });
        }
      });
    }
  };

  console.log("Backend Remark Data:", record.remark);
  console.log("Frontend table Remark output: ", remark);

  return (
    <textarea
      ref={textareaRef}
      value={remark}
      onChange={handleInputChange}
      onKeyDown={handleKeyPress}
      style={{
        width: "150px", 
        minHeight: "13px", 
        height: "15px",
        border: "1px solid lightgrey",
        padding: "2px 4px",
        borderRadius: "4px",
        overflow: "auto", 
        resize: "both", 
        boxSizing: "border-box" 
      }}
      rows="2"
      cols="70" 
    />
  );
};

export default RemarkInput;
