import React from 'react'
import styled from 'styled-components';

export default function NoContentTitle({ title, color }) {
  const customColor = color || "#d41d1d";

  const H1Style = styled.h1`
    text-align: center;
    margin: 3rem 0;
    padding: 3rem 0;
    color: ${(props) => props.customColor};
  `;

  return <H1Style customColor={customColor}>{title}</H1Style>;
}