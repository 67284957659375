import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Slidebar from "../components/sidebar/Slidebar";
import PageNotFoundComp from "../components/pagenotfound/PageNotFoundComp";
import Navbar from "../components/navbar/Navbar";
import { useSelector } from "react-redux";

const PageNotFound = () => {
    const navigate = useNavigate()


    const goBack=()=>{
      navigate(-1);
    }
  return (
    <>
    <PageNotFoundComp/>
    </>
  );
};

export default PageNotFound;


