import styled from "styled-components";
import PdfViewer from "../commonpage/PdfViewer";
import { CloseOutlined } from "@ant-design/icons";
import NoContentTitle from "../../components/atoms/NoContentTitle";

export const ViewModel = ({ performCancel, getData, designClientTable }) => {
    console.log('designClientTable: ', designClientTable);
    return (
      <>
        <div className="model-con">
          <div className="model-box" style={{ width: "80vw", height: "90vh" }}>
            <div style={{ height: "100%", overflow: "auto" }}>
              <h2 style={{ fontWeight: "bold", color: "#333" }}>Floor Plan</h2>
              {getData?.floor_plain ? 
                <PdfViewer data={getData?.floor_plain} /> : <NoContentTitle title="Upload Floor Plan"/>
                }
              <h2 style={{ fontWeight: "bold", color: "#333" }}>Mood Board</h2>
              {getData?.moon_board ?
                  <PdfViewer data={getData?.moon_board} /> : <NoContentTitle title="Upload Mood Board"/>
              }
              <h2 style={{ fontWeight: "bold", color: "#333" }}>Furniture Plan</h2>
              {getData?.proposed_furniture_plan ?
                <PdfViewer data={getData?.proposed_furniture_plan} /> : <NoContentTitle title="Upload Furniture Plan"/>
              } 
            </div>
  
            {/* {getData} */}
            {/* <div style={{ margin: "20px 0px" }}> */}
            <p className="p--cross--custom" onClick={() => performCancel()}>
              <CloseOutlined />
            </p>
            {/* </div> */}
          </div>
        </div>
      </>
    );
  };
