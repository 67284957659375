const aboutUsAp = [
    {
      id: 1,
      logo: "fas fa-eye",
      title: "Our Vision",
      info: "We will strive to offer our services in the best of quality, cost and time.",
    },
    {
      id: 2,
      logo: "fas fa-bullseye",
      title: "Our Mission",
      info: "Our mission is to achieve the vision at any cost.",
    },
  ];
  
  export default aboutUsAp;