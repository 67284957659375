import React from 'react'
import PdfViewer from '../../commonpage/PdfViewer';
import { useSelector } from 'react-redux';
import { url } from '../../../store/mutation/url';
import Slidebar from '../../../components/sidebar/Slidebar';
import NoContentTitle from '../../../components/atoms/NoContentTitle';

const FloorPlan = () => {
    const { user, userToken, loading, checkAuthLoading ,isAuthenticated} = useSelector(
      (state) => state.user
    );
    const {enquiry} = user 
    const {floor_plain} = enquiry || {}
    return (<>
  {/* <Slidebar/> */}
    <div  style={{margin:"auto"}}>
    <h2 className="e-table-h2">Floor Plan</h2>
    {floor_plain ? (
        <PdfViewer data={`${url}${floor_plain}`} />
      ) : (
        <NoContentTitle title="Floor plan is not uploaded" />
      )}
       
    </div>
  </>
  )
}

export default FloorPlan
