export const Pricing = [
    {
        designname: "ONE ROOM DESIGN",
        price: "₹3999",
        twodlayout: "2D Layouts." ,
        threedviews: "3D Views.",
        designlimit: "3D Design Upto 4 views's.",
        roomlimit: "Room Size** upto 200 sqft.",
        revisions: "One revisions",
        complementary: "",
        complementary2: "",
        calling: "",
        link: "https://www.instamojo.com/@SPACEMATE"
    },
    {
        designname: "2BHK DESIGN",
        price: "₹14999",
        twodlayout: "2D Layouts." ,
        threedviews: "3D Views.",
        designlimit: "3D Design Upto 4 views per room.",
        roomlimit: "",
        revisions: "Two Revisions",
        complementary: "Complementary Entrance lobby View",
        complementary2: "",
        calling: "",
        link: "https://www.instamojo.com/@SPACEMATE"
    },
    {
        designname: "3BHK DESIGN",
        price: "₹17999",
        twodlayout: "2D Layouts." ,
        threedviews: "3D Views.",
        designlimit: "3D Design Upto 4 views per room.",
        roomlimit: "",
        revisions: "Two Revisions",
        complementary: "Complementary Entrance lobby View",
        complementary2: "Complementary Internal Passage view",
        calling: "",
        link: "https://www.instamojo.com/@SPACEMATE"
    },
    {
        designname: "Individual House & Bungalows",
        price: "",
        twodlayout: "" ,
        threedviews: "",
        designlimit: "",
        roomlimit: "",
        revisions: "",
        complementary: "",
        complementary2: "",
        calling: "Call for costing.",
        link: "https://www.instamojo.com/@SPACEMATE"
    },
]
