import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const PageNotFoundComp = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  
  return (
    <Style>
      <div class="container">
        <div class="row">
          <div class="col-lg-2"></div>
          <div class="col-lg-8">
            <div class="col-lg-12 text-404">
              <b>404</b>
            </div>
            <div class="col-lg-12 text">
              <b> PAGE NOT FOUND </b>
            </div>
            <div class="col-lg-12 text-btn">
              <button
                class="btn btn-outline-primary"
                onClick={() => goBack()}
                className="btn"
              >
                Go Back
              </button>
            </div>
          </div>
          <div class="col-lg-2"></div>
        </div>
      </div>
    </Style>
  );
};

export default PageNotFoundComp;
const Style = styled.div`
  background: #141829;
  height: 100vh;
  .container {
    width: 100%;
    margin: 0 auto;
  }

  .row {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
  }

  .col {
    flex: 1;
  }

  .side-space {
    flex: 0 0 16.67%; /* 2 out of 12 columns */
  }

  .main-content {
    flex: 0 0 66.67%; /* 8 out of 12 columns */
    text-align: center;
  }

  .text-404 {
    font-size: 5rem; /* Example size, adjust as needed */
  }

  .text {
    font-size: 2rem; /* Example size, adjust as needed */
  }

  .text-btn {
    margin-top: 20px; /* Example spacing, adjust as needed */
  }

  .btn {
    background-color: transparent;
    border: 2px solid #007bff; /* Bootstrap primary color */
    color: #007bff; /* Bootstrap primary color */
    padding: 10px 20px;
    font-size: 1rem; /* Example size, adjust as needed */
    cursor: pointer;
  }

  .btn:hover {
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
  }

  .text-404 {
    margin-top: 80px;
    font-size: 200px;
    line-height: 200px;
    text-align: center;
    letter-spacing: 5px;
    background: -webkit-linear-gradient(#53cfcd, #0253a2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .text {
    text-align: center;
    font-size: 20px;
    color: #f6f6e3;
    letter-spacing: 5px;
    margin-top: 0px;
  }

  .text-btn {
    text-align: center;
    margin-top: 75px;
  }

  .btn-outline-primary {
    border-color: #ffc8c8;
    color: #ffc8c8;
    border-radius: 0px;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .btn-outline-primary:hover {
    background-color: #ffc8c8;
    right: 0px;
    border-color: #ffc8c8;
    color: #141829;
  }

  @media screen and (max-width: 500px) {
    .text-404 {
      font-size: 150px;
    }
  }

  @media screen and (max-width: 345px) {
    .text-404 {
      font-size: 120px;
    }
  }
`;
