import React from 'react'

const CustomerPage = ({user}) => {
  return (
    <div>
      
    </div>
  )
}

export default CustomerPage
