const WorkApi = [
    {
      id: 1,
      logo: "fas fa-pencil-ruler",
      title: "Innovative Design",
      info: "Our design consultant understands individual requirements in depth, based on their need and available space we offer best of the solution.",
    },
    {
      id: 2,
      logo: "fas fa-wallet",
      title: "Pocket Friendly",
      info: "We understand that designer solutions can be very costly business at times which is why our modus operandi is best suited to bring the cost down with innovative ideas and solutions that keeps the balance between cost and effective solutions.",
    },
    {
      id: 3,
      logo: "fas fa-user-tie",
      title: "Hassle Free Experience",
      info: "Spacemate is comprised of highly skilled individuals and experts with Industry experience. Their know how of working in a wide range of industries is what makes them best for your developing your space.",
    },
    
  ];
  
  export default WorkApi;