export const EmpVendorfields = [
    { name: "name", label: "Vendor Name", placeholder: "Enter Your Vendor Name", required: true, colSpan: 12 },
    { name: "number", label: "Contact No", placeholder: "Enter Vendor Phone No", type: "number", colSpan: 12, rules: [{ required: true, message: "Please enter contact number" }, { pattern: /^[0-9]+$/, message: "Only numbers are allowed" }] },
    { name: "email", label: "Email", placeholder: "Enter your Email", colSpan: 12, rules: [{ type: "email", message: "Please enter a valid email" }] },
    { name: "adhar_pan", label: "Adhar Pan", placeholder: "Enter Adhar Pan", colSpan: 12 },
    { name: "bank_name", label: "Bank Name", placeholder: "Enter Bank Name", colSpan: 12 },
    { name: "account_number", label: "Account Number", placeholder: "Enter Account Number", colSpan: 12, rules: [{ required: false, message: "Please enter account number" }, { pattern: /^[0-9]+$/, message: "Only numbers are allowed" }] },
    { name: "ifsc", label: "IFSC", placeholder: "Enter IFSC", colSpan: 12 },
    { name: "upi_detials", label: "UPI Details", placeholder: "Enter UPI", colSpan: 12 },
    { name: "permanent_address", label: "Permanent Address", placeholder: "Enter Permanent Address", colSpan: 12 },
    { name: "local_address", label: "Local Address", placeholder: "Enter Local Address", colSpan: 12 },
    { name: "field_experience", label: "Field Experience", placeholder: "Enter Field Experience", colSpan: 12 },
    { name: "service_provided", label: "Service Provided", placeholder: "Enter Service Provided", colSpan: 12 },
  ];
  