// InputField.js
import React from "react";
import { Form, Input, InputNumber } from "antd";
import styled from "styled-components";

const InputField = ({
  name,
  label,
  placeholder,
  type = "text",
  required = false,
  rules = [],
  ...rest
}) => {
  const Component = type === "number" ? InputNumber : Input;

  return (
    <InputStyle>
      <Form.Item
        name={name}
        label={label}
        // rules={[{ required, message: `Please input your ${label}!` }]}
        rules={required ? [{ required, message: `Please enter ${label}` }, ...rules] : rules}
        {...rest}
      >
        <Component placeholder={placeholder} style={{ width: "100%" }} />
      </Form.Item>
    </InputStyle>
  );
};

export default InputField;

const InputStyle = styled.div`
  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    font-weight: 500 !important;
  }
`;
