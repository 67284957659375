
import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const FormButtons = ({ datas, show }) => (
  <>
  <ButtonStyles>
    <Button
      type="primary"
      htmlType="submit"
      className="primary-button"
    >
      {datas ? "Update" : "Submit"}
    </Button>
    <Button danger type="primary" onClick={() => show(false)} className="cancel-button">
      Cancel
    </Button>
    </ButtonStyles>
  </>
);

export default FormButtons;

const ButtonStyles = styled.div`
margin-top: 2rem;
span, a, a:hover {
  text-decoration: none;
  color: inherit;
  padding: 0.5rem 0;
  font-size: 1.6rem;
}
.primary-button {
  margin-right: 20px;
  background: var(--sc-color);
  padding: 0.08rem 0.8rem;
  font-size: 1rem !important;
}

.cancel-button {
  background: #ff4d4f;
  border-color: #ff4d4f;
  padding: 0.08rem 0.9rem;
}
@media screen and (max-width: 700px) {
    margin-bottom: 2rem;
}
`;
