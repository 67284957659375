const productApi = [
  {
    id: 1,
    img: "reatil-01.png",
    title: "Open Display Rack",
  },
  {
    id: 2,
    img: "retail-sp-01.png",
    title: "Customized Rack",
  },
  {
    id: 3,
    img: "retail-sp-02.png",
    title: "Customized Rack",
  },
  {
    id: 4,
    img: "retail-sp-03.png",
    title: "Cloth Display Rack",
  },
  {
    id: 5,
    img: "retail-sp-04.png",
    title: "Jali Rack",
  },
];

export default productApi;
