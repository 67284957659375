import React from 'react'
import Slidebar from '../../components/sidebar/Slidebar'
import ModelUserTable from './table/ModelUserTable'

const ExecutionTable = () => {
  return (
    <>
      <Slidebar/>
      <ModelUserTable/>
    </>
  )
}

export default ExecutionTable
