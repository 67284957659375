import React from 'react'
import PdfViewer from '../../commonpage/PdfViewer';
import { useSelector } from 'react-redux';
import { url } from '../../../store/mutation/url';
import Slidebar from '../../../components/sidebar/Slidebar';
import NoContentTitle from '../../../components/atoms/NoContentTitle';
const MoodPlan = () => {
  const { user, userToken, loading, checkAuthLoading, isAuthenticated } =
    useSelector((state) => state.user);
  const { enquiry } = user;
  const { moon_board } = enquiry || {};
  return (
    <>
      <Slidebar />
      <div className="body-width-vw" style={{ margin: "auto" }}>
        <h2 className="e-table-h2">Mood dummy Board</h2>
        {moon_board ? (
          <PdfViewer data={`${url}${moon_board}`} />
        ) : (
          <NoContentTitle title="Mood Board is not uploaded" />
        )}
      </div>
    </>
  );
};


export default MoodPlan
